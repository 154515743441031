import { isArray } from 'lodash';
import React, { ChangeEvent } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { DEFAULT_LOCALE } from '../../../../../consts';
import { getDateLocale } from '../../../../../helpers/getDateLocale';
import { DEFAULT_DATE_FORMAT } from '../../constants';

import { CalendarHeader, ICalendarHeaderProps } from './CalendarHeader';
import {
  ActionButton,
  CalendarActions,
  CalendarFooter,
  CalendarTimePickerWrapper,
  ClearActionButton,
  StyledPopperWrapper,
} from './DatePicker.styled';
import { DatePickerContainer } from './DatePickerContainer';
import { TimePicker } from './TimePicker';
import { clampDateSafe } from './helpers';
import { IDatePickerProps, TDateCond, THandleDateChange } from './types';

const defaultCalendarParams: Partial<ReactDatePickerProps> = {
  disabledKeyboardNavigation: true,
  calendarContainer: DatePickerContainer,
  popperContainer: StyledPopperWrapper,
  popperModifiers: [
    {
      name: 'computeStyles',
      options: {
        // this option disables CSS transforms which
        // breaks proper positioning of inner poppers
        gpuAcceleration: false,
      },
    },
  ],
  // disable native time input rendering,
  // all time input is placed in the footer
  // eslint-disable-next-line react/jsx-no-useless-fragment
  customTimeInput: <></>,
  timeInputLabel: undefined,
};

export const DatePicker = <WithRange extends boolean | undefined = undefined>({
  locale = DEFAULT_LOCALE,
  dateFormat = DEFAULT_DATE_FORMAT,
  onChange = () => undefined,
  selected,
  monthsShown,
  showTodayButton,
  showClearButton,
  todayButtonText = 'Today',
  clearButtonText = 'Clear',
  showTimeInput,
  selectsRange,
  minDate,
  maxDate,
  startDate,
  endDate,
  onToday,
  onClear,
  ...rest
}: IDatePickerProps<WithRange>) => {
  const selectedSafe =
    typeof selected === 'string' ? new Date(selected) : selected;
  const localeSafe =
    typeof locale === 'string' ? getDateLocale(locale) : locale;

  const handleToday = (e: React.MouseEvent<HTMLButtonElement>) => {
    const today = clampDateSafe(new Date(), minDate, maxDate);
    onChange(
      selectsRange
        ? ([today, null] as TDateCond<WithRange>)
        : (today as TDateCond<WithRange>),
      e,
    );
    onToday?.();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    onChange(selectsRange ? ([null, null] as any) : null, e);
    onClear?.();
  };

  const handleChange: THandleDateChange<WithRange> = (value, evt) => {
    const valueClamped = isArray(value)
      ? (value.map((date) => date && clampDateSafe(date, minDate, maxDate)) as [
          Date,
          Date,
        ])
      : value && clampDateSafe(value, minDate, maxDate);
    onChange(valueClamped as TDateCond<WithRange>, evt);
  };

  const handleTimeStartChange = (
    date: Date,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange([date, endDate] as TDateCond<WithRange>, evt);
  };

  const handleTimeEndChange = (
    date: Date,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange([startDate, date] as TDateCond<WithRange>, evt);
  };

  const todayButton = showTodayButton && (
    <ActionButton onClick={handleToday} disableRipple>
      {todayButtonText}
    </ActionButton>
  );

  const clearButton = showClearButton && (
    <ClearActionButton onClick={handleClear} disableRipple>
      {clearButtonText}
    </ClearActionButton>
  );

  const actions = (todayButton || clearButton) && (
    <CalendarActions>
      {todayButton}
      {clearButton}
    </CalendarActions>
  );

  const timeInput = showTimeInput && (
    <CalendarTimePickerWrapper fullWidth={monthsShown > 1}>
      {selectsRange ? (
        <>
          <TimePicker
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleTimeStartChange}
          />
          <TimePicker
            selected={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleTimeEndChange}
          />
        </>
      ) : (
        <TimePicker<WithRange>
          selected={selectedSafe}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
        />
      )}
    </CalendarTimePickerWrapper>
  );

  const renderCustomHeader = (props: ICalendarHeaderProps) => (
    <CalendarHeader
      {...props}
      monthsShown={monthsShown}
      dateFormat={dateFormat}
      locale={localeSafe}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
    />
  );

  const customFooter = (actions || timeInput) && (
    <CalendarFooter>
      {timeInput}
      {actions}
    </CalendarFooter>
  );

  return (
    // extra div wraps base ReactDatepicker component
    // and its contents occasionally rendered next to it
    <div>
      <ReactDatePicker
        {...defaultCalendarParams}
        locale={localeSafe}
        selected={selectedSafe}
        onChange={handleChange}
        renderCustomHeader={renderCustomHeader}
        dateFormat={dateFormat}
        monthsShown={monthsShown}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        // this makes time picker and input synchronized,
        // see `ReactDatePicker.setSelected`
        showTimeInput={showTimeInput}
        {...rest}
      >
        {customFooter}
      </ReactDatePicker>
    </div>
  );
};
