import {
  FC,
  ReactNode,
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { accessRightsService } from '~/api';
import { useModulesData } from '~/pages/OrderManagement/hooks/useModulesData';
import {
  ContextOrdersProps,
  SelectedOrderProps,
} from '~/pages/OrderManagement/types';
import { createUUID } from '~/utils';

export const OrderManagementDataContext = createContext<ContextOrdersProps>({
  isBae: false,
  totalOrders: 0,
  dataModules: [],
  selectedOrder: [],
  defaultModule: '',
  handleSelectOrder: () => [],
  setIsSelectAll: () => {},
  isSelectAll: false,
  tableFilters: { module: 'some' },
  setTableFilters: () => {},
  setTotalOrders: () => {},
  requestId: '',
  setRequestId: () => {},
  username: '',
});

export const OrderManagementProvider: FC<ReactNode> = ({ children }) => {
  const { dataModules, defaultModule } = useModulesData();
  const [username, setUsername] = useState('');
  const [requestId, setRequestId] = useState('');
  const [totalOrders, setTotalOrders] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrderProps[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [tableFilters, setTableFilters] = useState<{ module: string } | null>(
    null,
  );

  const getUserData = useCallback(async () => {
    const data = await accessRightsService.getCurrentUserAccessRights();
    if (data) {
      setUsername(data?.username);
    }
  }, []);

  const isBae =
    tableFilters?.module.includes('broker-automation-engine') || null;

  const handleSelectOrder = useCallback(
    (value) => {
      const id = value.values.id;

      setSelectedOrder((prev) => {
        if (Array.isArray(value)) {
          return value;
        }

        if (prev.some((item) => item.values.id === id)) {
          return prev.filter((row) => {
            return row.values.id !== id;
          });
        }

        return [...prev, value];
      });
    },
    [setSelectedOrder],
  );

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!requestId) {
      setRequestId(createUUID());
    }
  }, [requestId]);

  const providerValue = useMemo(
    () => ({
      isBae,
      selectedOrder,
      handleSelectOrder,
      dataModules,
      defaultModule,
      setIsSelectAll,
      isSelectAll,
      tableFilters,
      setTableFilters,
      totalOrders,
      setTotalOrders,
      requestId,
      setRequestId,
      username,
    }),
    [
      isBae,
      selectedOrder,
      handleSelectOrder,
      dataModules,
      defaultModule,
      setIsSelectAll,
      isSelectAll,
      tableFilters,
      setTableFilters,
      totalOrders,
      setTotalOrders,
      requestId,
      setRequestId,
      username,
    ],
  );

  return (
    <OrderManagementDataContext.Provider value={providerValue}>
      {children}
    </OrderManagementDataContext.Provider>
  );
};
