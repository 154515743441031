import { styled } from '@mui/material/styles';

export const FullWidthField = {
  width: '100%',
};

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  background: 'white',
  padding: '24px 24px 0',
});

export const Fields = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
});

export const Field = styled('div')({ width: '32% ', marginRight: '24px' });

export const ExtraField = styled('div')({
  width: '32% ',
  marginTop: '24px',
  marginRight: '24px',
});

export const AllFields = styled('div')({ width: '90% ' });
