import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../../helpers/blockNonNativeProps';

interface IRootProps {
  isExpanded: boolean;
}

export const Root = styled('div', {
  shouldForwardProp: blockNonNativeProps('isExpanded'),
})<IRootProps>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${(props) => (props.isExpanded ? 'currentColor' : '#818C99')};
  border: 1.5px solid currentColor;
  display: inline-grid;
  place-content: center;
  margin-right: 10px;
  transform: ${(props) => (props.isExpanded ? 'rotate(90deg)' : 'none')};
`;
