import {
  FC,
  ReactNode,
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';

import { orderManagementService } from 'api';
import { DEFAULT_POOLING_INTERVAL_DATA } from '~/constants';
import { useInterval } from '~/hooks';

import { STATUS_FINISH } from './constants';

interface DataReportInfoProps {
  stepOneName: string;
  stepTwoName: string;
  date: string;
  waitingForStartCount: number;
  inProcessCount: number;
  total: number;
  stepOneFailureCount: number;
  stepTwoFailureCount: number;
  successCount: number;
  status: string;
}

interface ReportInfoProps {
  isLoadingReportInfo: boolean;
  dataReportInfo: DataReportInfoProps | null;
}

export const ReportInfoContext = createContext<ReportInfoProps>({
  dataReportInfo: null,
  isLoadingReportInfo: false,
});

export const ReportInfoProvider: FC<ReactNode> = ({ children }) => {
  const { id } = useParams();
  const [dataReportInfo, setDataReportInfo] =
    useState<DataReportInfoProps | null>(null);
  const [isLoadingReportInfo, setIsLoadingReportInfo] = useState(false);

  const getOrderReportInfo = async (actionId: string, isPolling?: boolean) => {
    if (!isPolling) {
      setIsLoadingReportInfo(true);
    }
    const response = await orderManagementService.getOrderReportInfo(actionId);

    if (!isPolling) {
      setIsLoadingReportInfo(false);
    }
    setDataReportInfo(response);
  };

  useEffect(() => {
    if (id) {
      getOrderReportInfo(id);
    }
  }, []);

  useInterval(() => {
    if (id && dataReportInfo && dataReportInfo.status !== STATUS_FINISH) {
      getOrderReportInfo(id, true);
    }
  }, DEFAULT_POOLING_INTERVAL_DATA);

  const providerValue = useMemo(
    () => ({
      dataReportInfo,
      isLoadingReportInfo,
    }),
    [dataReportInfo, isLoadingReportInfo],
  );

  return (
    <ReportInfoContext.Provider value={providerValue}>
      {children}
    </ReportInfoContext.Provider>
  );
};
