import { useCallback, useContext, useMemo } from 'react';
import { useTableData } from 'react-ui-kit-exante';

import { orderManagementService } from 'api';
import { OrderManagementDataContext } from '~/pages/OrderManagement/OrderManagementProvider';
import { getTableId, calculateCountOfPages } from '~/utils';

import { OrdersProps } from '../../types';

export function useReportsTableData() {
  const { username } = useContext(OrderManagementDataContext);
  const getReports = useCallback((values) => {
    return orderManagementService.getReportsList(values.params);
  }, []);

  const getDefaultFilters = () => {
    return {
      username,
    };
  };

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: getReports,
      },
      tableId: getTableId('ReportsTable'),
      saveViewParamsAfterLeave: true,
      filters: {
        getDefaultFilters,
      },
    }),
    [getReports],
  );

  const {
    data,
    page,
    skip,
    limit,
    setPage,
    setLimit,
    isLoading,
    setFilter,
    setSorting,
    resetFilters,
    removeFilter,
    filters: filtersTable,
  } = useTableData<OrdersProps | null>(tableArgs);

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [resetFilters, filtersTable],
  );

  return {
    data,
    isLoading,
    setFilter,
    removeFilter,
    setSorting,
    filteringProps,
    serverPaginationProps,
  };
}
