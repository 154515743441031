import { styled } from '@mui/material/styles';
import { HTMLAttributes } from 'react';

import ArrowDown from '../../../Icons/components/ArrowDown';
import ArrowTop from '../../../Icons/components/ArrowTop';

export type SortButtonProps = HTMLAttributes<HTMLSpanElement>;

export const SortButtonContainer = styled('span')<SortButtonProps>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2,

    '& .MuiIcon-colorAction': {
      color: theme.color.icon.primary,
    },
    '& .MuiIcon-colorDisabled': {
      color: theme.color.icon.secondary,
    },
  }),
);

export const StyledSortButtonUp = styled(ArrowTop)(() => ({
  transform: `translateY(3px)`,
}));

export const StyledSortButtonDown = styled(ArrowDown)(() => ({
  transform: `translateY(-3px)`,
}));
