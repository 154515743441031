import { useLayoutEffect, useState } from 'react';

import { applicationService } from 'api';

import HeaderStyles from './Header.module.css';

export const Header = () => {
  const [version, setVersion] = useState('');

  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      applicationService.getAppVersion().then(setVersion);
    }
  }, []);

  return (
    <header className={HeaderStyles.Header}>
      <div>
        <div className={HeaderStyles.Title}>Order Management</div>
        <div className={HeaderStyles.Version}>{version}</div>
      </div>
    </header>
  );
};
