export enum NameTabs {
  NotСancelled,
  NotPlaced,
  Fulfilled,
}

export const COLUMNS = [
  {
    Header: 'Order Id',
    accessor: 'orderId',
    disableSortBy: true,
  },
  {
    Header: 'Updated',
    accessor: 'Updated',
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'orderStatus',
    disableSortBy: true,
  },
  {
    Header: 'Instrument',
    accessor: 'instrument',
    disableSortBy: true,
  },
];

export const STATUS_FINISH = 'FINISH';
