import { styled } from '@mui/material/styles';
import { TDefaultTheme } from 'react-ui-kit-exante';

interface CounterProps {
  theme?: TDefaultTheme;
  isActive: boolean;
}

export const Counter = styled('span')(({ theme, isActive }: CounterProps) => ({
  padding: '1px 10px',
  borderRadius: '20px',
  border: `2px solid ${
    isActive ? theme?.palette?.text?.primary : theme?.palette?.primary?.main
  }`,
}));
