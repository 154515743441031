import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { useTheme } from '../../theme';

import { StyledTabs } from './styled';
import { IUITabsProps } from './types';

export const Tabs: FC<IUITabsProps> = ({
  children,
  className,
  value,
  onChange,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledTabs
        className={className}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {children}
      </StyledTabs>
    </ThemeProvider>
  );
};
