import { IColumn, FilterValue, ICellValue } from 'react-ui-kit-exante';

import { OrderProps } from '~/pages/OrderManagement/types';
import { formatDate } from '~/utils';

import { StatusCell } from '../../components/StatusCell';

interface ColumnsProps {
  optionsModule: string[];
  onRemove(col: string): void;
  onFilter(col: string, value: FilterValue): void;
}

export const useColumns = ({
  onRemove,
  onFilter,
  optionsModule,
}: ColumnsProps): IColumn<OrderProps>[] => {
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Account',
      accessor: 'account',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Broker url',
      accessor: 'brokerUrl',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      disableFilters: true,
      Cell: StatusCell,
    },
    {
      Header: 'Module',
      accessor: 'module',
      disableSortBy: true,
      onRemove,
      onFilter,
      type: 'select',
      filterOptions: optionsModule,
    },
    {
      Header: 'Update',
      accessor: 'lastUpdate',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'dateTimeUTC',
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const localTime = values?.lastUpdate?.localTime;

        return <span>{localTime ? formatDate(new Date(localTime)) : '–'}</span>;
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const type = values?.type?.type;

        return <span>{type || '–'}</span>;
      },
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Side',
      accessor: 'side',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Price',
      accessor: 'originalLimitPrice',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
      align: 'left',
    },
    {
      Header: 'Filled',
      accessor: 'fills',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const fills = values.fills;

        const amountQuantity = fills?.reduce(
          (accumulator: number, item: { quantity: string }) => {
            return accumulator + Number(item?.quantity);
          },
          0,
        );

        return <span>{amountQuantity || 0}</span>;
      },
    },
    {
      Header: 'Average price',
      accessor: 'averagePrice',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Broker account',
      accessor: 'brokerAccount',
      disableSortBy: true,
      disableFilters: true,
      hideInMenu: true,
    },
    {
      Header: 'Account name',
      accessor: 'brokerAccountName',
      disableSortBy: true,
      onRemove,
      onFilter,
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const accountName = values?.brokerAccount?.accountName;

        return <span>{accountName || '–'}</span>;
      },
    },
    {
      Header: 'Gateway name',
      accessor: 'brokerGatewayName',
      disableSortBy: true,
      onRemove,
      onFilter,
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const gatewayName = values?.brokerAccount?.gatewayName;

        return <span>{gatewayName || '–'}</span>;
      },
    },
    {
      Header: 'Provider name',
      accessor: 'brokerProviderName',
      disableSortBy: true,
      onRemove,
      onFilter,
      Cell: ({ row: { values } }: ICellValue<OrderProps>) => {
        const providerName = values?.brokerAccount?.providerName;

        return <span>{providerName || '–'}</span>;
      },
    },
  ];

  return columns;
};
