import { Root } from './TableExpandIcon.styled';

interface ITableExpandProps {
  width?: number;
  height?: number;
  isExpanded?: boolean;
}

export const TableExpandIcon = ({
  width = 6,
  height = 8,
  isExpanded = false,
}: ITableExpandProps) => {
  return (
    <Root isExpanded={isExpanded}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.3335 1L4.3335 4L1.3335 7"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Root>
  );
};
