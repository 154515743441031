import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Instruments = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.551 6.125a3.897 3.897 0 013.898 0v0A3.897 3.897 0 0113.397 9.5v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 015.603 9.5v0c0-1.392.743-2.679 1.948-3.375v0zM7.551 19.125a3.897 3.897 0 013.898 0v0a3.897 3.897 0 011.948 3.375v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 015.603 22.5v0c0-1.392.743-2.679 1.948-3.375v0zM20.551 6.125a3.897 3.897 0 013.898 0v0A3.897 3.897 0 0126.397 9.5v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 0118.603 9.5v0c0-1.392.743-2.679 1.948-3.375v0zM20.551 19.125a3.897 3.897 0 013.898 0v0a3.897 3.897 0 011.948 3.375v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0a3.897 3.897 0 01-1.948-3.375v0c0-1.392.743-2.679 1.948-3.375v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.5 5.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L10.5 14.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866V8.244a1 1 0 01.5-.866L9.5 5.622zM9.5 17.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L10.5 26.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866v-3.512a1 1 0 01.5-.866L9.5 17.622zM21.5 5.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L22.5 14.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866V8.244a1 1 0 01.5-.866L21.5 5.622zM21.5 17.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L22.5 26.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866v-3.512a1 1 0 01.5-.866l3.041-1.756z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.5 5.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L10.5 14.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866V8.244a1 1 0 01.5-.866L9.5 5.622zM9.5 17.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L10.5 26.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866v-3.512a1 1 0 01.5-.866L9.5 17.622zM21.5 5.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L22.5 14.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866V8.244a1 1 0 01.5-.866L21.5 5.622zM21.5 17.622a1 1 0 011 0l3.041 1.756a1 1 0 01.5.866v3.512a1 1 0 01-.5.866L22.5 26.378a1 1 0 01-1 0l-3.041-1.756a1 1 0 01-.5-.866v-3.512a1 1 0 01.5-.866l3.041-1.756z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
          >
            <path d="M7.551 6.125a3.897 3.897 0 013.898 0v0A3.897 3.897 0 0113.397 9.5v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 015.603 9.5v0c0-1.392.743-2.679 1.948-3.375v0zM7.551 19.125a3.897 3.897 0 013.898 0v0a3.897 3.897 0 011.948 3.375v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 015.603 22.5v0c0-1.392.743-2.679 1.948-3.375v0zM20.551 6.125a3.897 3.897 0 013.898 0v0A3.897 3.897 0 0126.397 9.5v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0A3.897 3.897 0 0118.603 9.5v0c0-1.392.743-2.679 1.948-3.375v0zM20.551 19.125a3.897 3.897 0 013.898 0v0a3.897 3.897 0 011.948 3.375v0a3.897 3.897 0 01-1.948 3.375v0a3.897 3.897 0 01-3.898 0v0a3.897 3.897 0 01-1.948-3.375v0c0-1.392.743-2.679 1.948-3.375v0z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Instruments;
