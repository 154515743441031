import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Tabs,
  Tab,
  TabPanel,
  Loader,
  ISelectOption,
} from 'react-ui-kit-exante';

import { orderManagementService } from 'api';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import { extraOptionsDestinationGateway } from '../../constants';
import ActionTable from '../ActionsTable';
import { ReportsTables } from '../ReportsTable';
import { SettingsForm } from '../SettingsForm';

import { Wrapper, Head, WrapperLoader } from './OrderManagementTables.styled';

export const OrderManagementTables = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dataModules, username } = useContext(OrderManagementDataContext);
  const [activeTab, setActiveTab] = useState(
    location?.state?.activeReportsTab ? 1 : 0,
  );
  const [firstSelect, setFirstSelect] = useState('');
  const [secondSelect, setSecondSelect] = useState(DEFAULT_EMPTY_PLACEHOLDER);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [destinationGateway, setDestinationGateway] = useState('');
  const [brokerUrl, setBrokerUrl] = useState('');
  const [providerName, setProviderName] = useState('');
  const [gatewayName, setGatewayName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [optionsDestinationGateway, setOptionsDestinationGateway] = useState<
    ISelectOption[]
  >(extraOptionsDestinationGateway);

  const getBrokerUrls = async () => {
    const dataOrderGateways = await orderManagementService.getBrokerUrls();

    const prepareData = dataOrderGateways?.data?.map((item: string) => {
      return { value: item, label: item };
    });

    if (prepareData) {
      setOptionsDestinationGateway([
        ...optionsDestinationGateway,
        ...prepareData,
      ]);
    }
  };

  useEffect(() => {
    getBrokerUrls();
  }, []);

  return dataModules.length > 0 ? (
    <>
      <Wrapper>
        <Head>Order Management</Head>
        <Tabs
          value={activeTab}
          onChange={(e, value) => {
            setActiveTab(value);
            navigate('/om/orders');
          }}
        >
          <Tab label="Actions" />
          <Tab label="Reports" />
        </Tabs>
      </Wrapper>
      <TabPanel value={activeTab} index={0}>
        <SettingsForm
          brokerUrl={brokerUrl}
          gatewayName={gatewayName}
          accountName={accountName}
          firstSelect={firstSelect}
          secondSelect={secondSelect}
          setBrokerUrl={setBrokerUrl}
          providerName={providerName}
          setFirstSelect={setFirstSelect}
          setAccountName={setAccountName}
          setGatewayName={setGatewayName}
          setSecondSelect={setSecondSelect}
          setProviderName={setProviderName}
          setIsActionLoading={setIsActionLoading}
          destinationGateway={destinationGateway}
          setDestinationGateway={setDestinationGateway}
          optionsDestinationGateway={optionsDestinationGateway}
        />
        <ActionTable
          setFirstSelect={setFirstSelect}
          setSecondSelect={setSecondSelect}
          setDestinationGateway={setDestinationGateway}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {username ? (
          <ReportsTables />
        ) : (
          <WrapperLoader>
            <Loader isCentered size="l" />
          </WrapperLoader>
        )}
      </TabPanel>
    </>
  ) : (
    <Loader isCentered size="l" />
  );
};
