import { styled } from '@mui/material';

export const RefreshButtonStyled = styled('button')(({ theme }) => ({
  background: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  cursor: 'pointer',
  padding: '0px',
  color: theme?.color.typo?.action,
  '&:disabled': {
    cursor: 'auto',
    color: theme?.palette.text?.disabled,
  },
  '&:hover:not(:disabled), &:focus:not(:disabled)': {
    color: theme?.color.typo?.promo,
  },
  '& > svg': {
    width: '20px',
    height: '20px',
  },
}));
