import React from 'react';
import ReactDOM from 'react-dom';

import { Application } from '~/pages/Application';
import { ComposedThemeProvider } from '~/theme/ComposedThemeProvider';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  ReactDOM.render(
    <React.StrictMode>
      <ComposedThemeProvider>
        <Application />
      </ComposedThemeProvider>
    </React.StrictMode>,
    rootNode,
  );
};
