import { useContext, useEffect, useState, memo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';
import { getTableId } from '~/utils';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import { DEFAULT_SORTING, DISPLAYED_COLUMNS_KEYS } from '../../constants';
import { useColumns, useOrdersTableData } from '../../hooks';

interface ActionTableProps {
  setFirstSelect: (firstSelect: string) => void;
  setSecondSelect: (secondSelect: string) => void;
  setDestinationGateway: (destinationGateway: string) => void;
}

const ActionTable = ({
  setFirstSelect,
  setSecondSelect,
  setDestinationGateway,
}: ActionTableProps) => {
  const [refetch, setRefetch] = useState(false);

  const {
    handleSelectOrder,
    selectedOrder,
    dataModules,
    setIsSelectAll,
    isSelectAll,
    setTotalOrders,
  } = useContext(OrderManagementDataContext);
  const {
    data,
    isLoading,
    setFilter,
    removeFilter,
    filteringProps,
    additionalActions,
    serverPaginationProps,
  } = useOrdersTableData(refetch, setRefetch);

  const resetSettings = () => {
    setFirstSelect('');
    setSecondSelect(DEFAULT_EMPTY_PLACEHOLDER);
    setDestinationGateway('');
    setIsSelectAll(false);
    handleSelectOrder([]);
  };

  const columns = useColumns({
    onFilter: (column, value) => {
      resetSettings();
      setFilter(column, value);
    },
    onRemove: (column) => {
      resetSettings();
      removeFilter(column);
    },
    optionsModule: dataModules,
  });

  useEffect(() => {
    const total = data?.pagination?.total;

    if (total) {
      setTotalOrders(total);
    }
  }, [data]);

  return (
    <Table
      hasFilters
      isFlexLayout
      showTableInfo
      hasPagination
      pageSize={20}
      isPinnedHeader
      columns={columns}
      data={data?.data || []}
      saveViewParamsAfterLeave
      filteringProps={filteringProps}
      defaultSortBy={DEFAULT_SORTING}
      additionalActions={additionalActions}
      bulkActions={{
        isSelectAll,
        setIsSelectAll,
        selectedRows: selectedOrder,
        setSelectedRows: handleSelectOrder,
      }}
      isLoading={isLoading}
      tableId={getTableId('OrdersTable')}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      serverPaginationProps={serverPaginationProps}
    />
  );
};

export default memo(ActionTable);
