import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes, Router } from 'react-router-dom';
import {
  NotificationProvider,
  ThemeProvider,
  useTheme,
} from 'react-ui-kit-exante';

import { OrderManagement } from 'pages/OrderManagement';
import { ReportInfo } from 'pages/ReportInfo';
import { ApplicationWrapper } from '~/components/ApplicationWrapper';
import { ErrorFallback } from '~/components/ErrorFallback';
import { Header } from '~/components/Header';
import { REPORT_INFO, ORDERS_TABLE } from '~/routes';
import { getBaseName } from '~/utils/getBaseName';

import '../../styles/Global.css';

const InnerComponent = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Routes>
        <Route path={REPORT_INFO} element={<ReportInfo />} />
        <Route path={ORDERS_TABLE} element={<OrderManagement />} />
      </Routes>
    </ErrorBoundary>
  );
};

export const Application = () => {
  const history = window.runUIhistoryContainer;
  const theme = useTheme();
  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider />
      {window.runUIhistoryContainer ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <InnerComponent />
        </Router>
      ) : (
        <BrowserRouter basename={getBaseName()}>
          <Header />
          <ApplicationWrapper>
            <InnerComponent />
          </ApplicationWrapper>
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
};
