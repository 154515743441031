import { useContext, useMemo, ChangeEvent, useCallback } from 'react';
import {
  Button,
  Select,
  Input,
  Modal,
  Tooltip,
  useModal,
} from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

import { OrderManagementDataContext } from '../../OrderManagementProvider';
import {
  ActionNames,
  optionsFirstSelect,
  optionsSecondSelect,
  disabledOptionsFirstSelect,
  ExtraDestinationGateway,
} from '../../constants';
import { usePostOrders } from '../../hooks';

import {
  Fields,
  Wrapper,
  FullWidthField,
  Field,
  AllFields,
  ExtraField,
} from './SettingsForm.styled';
import { SettingsFormProps } from './types';

export const SettingsForm = ({
  firstSelect,
  setFirstSelect,
  secondSelect,
  setSecondSelect,
  brokerUrl,
  setBrokerUrl,
  providerName,
  setProviderName,
  gatewayName,
  setGatewayName,
  accountName,
  setAccountName,
  setIsActionLoading,
  destinationGateway,
  setDestinationGateway,
  optionsDestinationGateway,
}: SettingsFormProps) => {
  const confirmModal = useModal();
  const { selectedOrder, isBae, isSelectAll, totalOrders, setRequestId } =
    useContext(OrderManagementDataContext);
  const { postOrders } = usePostOrders({
    brokerUrl,
    firstSelect,
    secondSelect,
    setIsActionLoading,
    providerName,
    gatewayName,
    accountName,
    destinationGateway,
  });

  const countOrders = selectedOrder.length;
  const disabledFirstSelect = isSelectAll ? !isSelectAll : !countOrders;

  const numbersSelectedOrder = useMemo(
    () => (isSelectAll ? totalOrders - countOrders : countOrders),
    [isSelectAll, totalOrders, countOrders],
  );

  const disabledSecondSelect =
    firstSelect === '' ||
    firstSelect === ActionNames.ReplaceOnNextRoute ||
    firstSelect === ActionNames.ReplaceOnInitialRoute;

  const disabledConfirm = () => {
    if (firstSelect && secondSelect === DEFAULT_EMPTY_PLACEHOLDER) {
      return false;
    }
    if (destinationGateway) {
      if (destinationGateway === ExtraDestinationGateway.Same) {
        return false;
      }
      if (destinationGateway === ExtraDestinationGateway.Bae && brokerUrl) {
        return false;
      }
      if (
        destinationGateway !== ExtraDestinationGateway.Bae &&
        destinationGateway !== ExtraDestinationGateway.Same &&
        providerName &&
        gatewayName &&
        accountName
      ) {
        return false;
      }
    }

    return true;
  };

  const resetExtraFields = () => {
    setBrokerUrl('');
    setGatewayName('');
    setAccountName('');
    setProviderName('');
  };

  const handleChangeFirstSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstSelect(e.target.value);
    setSecondSelect(DEFAULT_EMPTY_PLACEHOLDER);
    setDestinationGateway('');
  };

  const handleChangeSecondSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondSelect(e.target.value);
    setDestinationGateway('');
    resetExtraFields();
  };

  const onChangeBrokerUrlHandler = useCallback(
    (e) => {
      setBrokerUrl(e.target.value || null);
    },
    [setBrokerUrl],
  );

  const onChangeProviderNameHandler = useCallback(
    (e) => {
      setProviderName(e.target.value || null);
    },
    [setProviderName],
  );

  const onChangeGatewayNameHandler = useCallback(
    (e) => {
      setGatewayName(e.target.value || null);
    },
    [setGatewayName],
  );

  const onChangeAccountNameHandler = useCallback(
    (e) => {
      setAccountName(e.target.value || null);
    },
    [setAccountName],
  );

  const renderExtraField = useCallback(() => {
    if (
      destinationGateway &&
      destinationGateway !== ExtraDestinationGateway.Same
    ) {
      if (destinationGateway === ExtraDestinationGateway.Bae) {
        return (
          <ExtraField>
            <Input
              value={brokerUrl}
              sx={FullWidthField}
              placeholder="Broker Url"
              onChange={onChangeBrokerUrlHandler}
            />
          </ExtraField>
        );
      }
      return (
        <>
          <ExtraField>
            <Input
              sx={FullWidthField}
              value={providerName}
              placeholder="Provider Name"
              onChange={onChangeProviderNameHandler}
            />
          </ExtraField>
          <ExtraField>
            <Input
              value={gatewayName}
              onChange={onChangeGatewayNameHandler}
              sx={FullWidthField}
              placeholder="Gateway Name"
            />
          </ExtraField>
          <ExtraField>
            <Input
              value={accountName}
              onChange={onChangeAccountNameHandler}
              sx={FullWidthField}
              placeholder="Account Name"
            />
          </ExtraField>
        </>
      );
    }

    return null;
  }, [brokerUrl, destinationGateway, providerName, gatewayName, accountName]);

  return (
    <Wrapper>
      <AllFields>
        <Fields>
          <Tooltip
            style={{ width: '32%', marginRight: '24px' }}
            placement="bottom-start"
            title="Please select orders in the table below"
            disableHoverListener={countOrders > 0 || isSelectAll}
          >
            <div style={{ width: '100%' }}>
              <Select
                sx={FullWidthField}
                placeholder="Choose"
                value={firstSelect}
                options={
                  isBae ? optionsFirstSelect : disabledOptionsFirstSelect
                }
                onChange={handleChangeFirstSelect}
                disabled={disabledFirstSelect}
              />
            </div>
          </Tooltip>
          <Field>
            <Select
              sx={FullWidthField}
              placeholder="Choose"
              value={secondSelect}
              options={optionsSecondSelect}
              onChange={handleChangeSecondSelect}
              disabled={disabledSecondSelect || disabledFirstSelect}
            />
          </Field>
          <Field>
            <Select
              sx={FullWidthField}
              value={destinationGateway}
              placeholder="Destination Gateway"
              options={optionsDestinationGateway}
              disabled={
                secondSelect === DEFAULT_EMPTY_PLACEHOLDER ||
                disabledFirstSelect
              }
              onChange={(e) => {
                resetExtraFields();
                setDestinationGateway(e.target.value);
              }}
            />
          </Field>
        </Fields>
        <Fields>{renderExtraField()}</Fields>
      </AllFields>
      <Button onClick={confirmModal.onOpen} disabled={disabledConfirm()}>
        Confirm
      </Button>
      <Modal
        isOpened={confirmModal.isOpened}
        onClose={() => {
          setRequestId('');
          confirmModal.onClose();
        }}
        title={`Are you sure that want to update ${numbersSelectedOrder} orders?`}
        confirmButton={{
          handleConfirm: postOrders,
        }}
        keepMounted={false}
      >
        {/* need a children/ */}
      </Modal>
    </Wrapper>
  );
};
