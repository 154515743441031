import { Notification } from 'react-ui-kit-exante';

import { Notifications } from '~/constants';
import {
  FetchReportsListProps,
  OrdersParamsProps,
  PostOrdersParamsProps,
} from '~/pages/OrderManagement/types';
import { IPaginationParameters } from '~/types/api';
import { Logger } from '~/types/logger';

import { OrderManagementRepository } from './orderManagement.repository';

export class OrderManagementService {
  public logger: Logger;

  public constructor(logger: Logger) {
    this.logger = logger;
  }

  public async getOrders(params: OrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.fetchOrderManagement(
        params,
      );

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async postInternalOrders(params: PostOrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifyInternalOrders(
        params,
      );
      Notification.success({
        title: Notifications.Success,
      });
      return data;
    } catch (error) {
      this.logger.error(error);
      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async postBrokerOrders(params: PostOrdersParamsProps) {
    try {
      const { data } = await OrderManagementRepository.modifyBrokerOrders(
        params,
      );
      Notification.success({
        title: Notifications.Success,
      });
      return data;
    } catch (error) {
      this.logger.error(error);
      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getModules() {
    try {
      const { data } = await OrderManagementRepository.fetchModules();

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getOrderReportInfo(id: string) {
    try {
      const { data } = await OrderManagementRepository.fetchOrderReportInfo(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getOrderReportStepOneList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportStepOneList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getOrderReportStepTwoList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportStepTwoList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getOrderReportSuccessList(id: string) {
    try {
      const { data } =
        await OrderManagementRepository.fetchOrderReportSuccessList(id);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getReportsList(params: FetchReportsListProps) {
    try {
      const { data } = await OrderManagementRepository.fetchReportsList(params);

      return data;
    } catch (error) {
      this.logger.error(error);

      Notification.error({
        title: Notifications.Error,
      });

      return null;
    }
  }

  public async getBrokerUrls() {
    try {
      const { data } = await OrderManagementRepository.fetchBrokerUrls();

      return data;
    } catch (error) {
      this.logger.error(error);

      return null;
    }
  }
}
