import { isObject, isFunction, get } from 'lodash';

import {
  ActionCellStyled,
  CellStyled,
  CellTextContainerStyled,
} from '../Table.styled';
import { TableExpandIcon } from '../components';

import { WithTooltip } from './WithTooltip';
import { TableCellProps } from './types';

export const TableCell = <Data extends object>({
  displaysOfTreeLines,
  expanded,
  id,
  isFirstInRow,
  render,
  row,
  tooltip = 'default',
  cellProps,
  handleCellClick,
  value,
  tableState,
  cellStyle,
}: TableCellProps<Data>) => {
  const isActionsColumn = id === 'actions';
  const isCanExpanded =
    row.canExpand ||
    (isObject(expanded) &&
      isFunction(expanded.canExpandDefinition) &&
      expanded.canExpandDefinition(row));

  const CellComponent = isActionsColumn ? ActionCellStyled : CellStyled;

  const style = {
    ...(get(cellProps, 'style') || {}),
    ...cellStyle,
  };

  return (
    <CellComponent
      {...cellProps}
      data-test-id={
        isActionsColumn ? 'table__body--actions-cell' : 'table__body--cell'
      }
      onClick={handleCellClick}
      depth={row.depth}
      displaysOfTreeLines={displaysOfTreeLines}
      isCanExpanded={isCanExpanded}
      isExpandingTable={Boolean(expanded)}
      isFirstInRow={isFirstInRow}
      style={style}
    >
      <WithTooltip value={value} tooltip={tooltip}>
        <CellTextContainerStyled className="CellTextContainer">
          {isFirstInRow && isCanExpanded ? (
            <TableExpandIcon isExpanded={row.isExpanded} />
          ) : null}

          {render('Cell', { tableState })}
        </CellTextContainerStyled>
      </WithTooltip>
    </CellComponent>
  );
};
