import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { orderManagementService } from 'api';
import { OrderManagementDataContext } from '~/pages/OrderManagement/OrderManagementProvider';
import {
  ActionNames,
  MethodNames,
  TypesOrder,
} from '~/pages/OrderManagement/constants';
import { PostOrdersBodyProps } from '~/pages/OrderManagement/types';
import { REPORTS } from '~/routes';

interface UsePostOrdersProps {
  brokerUrl?: string;
  firstSelect: string;
  secondSelect: string;
  gatewayName?: string;
  accountName?: string;
  providerName?: string;
  setIsActionLoading: (isActionLoading: boolean) => void;
  destinationGateway: string;
}

export function usePostOrders({
  brokerUrl,
  gatewayName,
  accountName,
  providerName,
  firstSelect,
  secondSelect,
  setIsActionLoading,
  destinationGateway,
}: UsePostOrdersProps) {
  const { selectedOrder, tableFilters, isSelectAll, totalOrders, requestId } =
    useContext(OrderManagementDataContext);
  const navigate = useNavigate();

  const redirectToReportInfoPage = (actionId: string) => {
    if (actionId) {
      navigate(`/${REPORTS}/${actionId}`);
    }
  };

  const preparePostData = () => {
    const methodName = () => {
      if (firstSelect === ActionNames.ReplaceOnNextRoute) {
        return MethodNames.PlaceOnNextRoute;
      }
      if (firstSelect === ActionNames.ReplaceOnInitialRoute) {
        return MethodNames.PlaceOnInitialRoute;
      }
      if (
        firstSelect === ActionNames.CancelViaBrokerProtocol &&
        secondSelect === ActionNames.Reset
      ) {
        return MethodNames.Cancel;
      }
      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        return MethodNames.Replace;
      }

      return MethodNames.MarkCancelled;
    };

    const method = methodName();

    const getOrderIdList = () => selectedOrder.map(({ values }) => values?.id);

    const getParamsForSomeOrders = () => {
      const body: PostOrdersBodyProps = {
        requestId,
        comment: '-',
        orderIdList: getOrderIdList(),
      };

      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        body.cancelStrategy =
          firstSelect === ActionNames.CancelViaBrokerProtocol
            ? TypesOrder.Broker
            : TypesOrder.Internal;
      }

      if (!brokerUrl && accountName) {
        body.brokerUrl = destinationGateway;
        body.gatewayName = gatewayName;
        body.accountName = accountName;
        body.providerName = providerName;
      }

      if (brokerUrl) {
        body.brokerUrl = brokerUrl;
        body.gatewayName = gatewayName;
        body.accountName = accountName;
        body.providerName = providerName;
      }

      return body;
    };

    const getParamsForAllOrders = () => {
      const body: PostOrdersBodyProps = {
        requestId,
        comment: '-',
        excludeOrderIdList: getOrderIdList(),
        ordersFilter: { ...tableFilters, limit: totalOrders, skip: 0 },
      };

      if (secondSelect === ActionNames.PlaceViaBrokerProtocol) {
        body.cancelStrategy =
          firstSelect === ActionNames.CancelViaBrokerProtocol
            ? TypesOrder.Broker
            : TypesOrder.Internal;
      }

      if (!brokerUrl && providerName) {
        body.gatewayName = gatewayName;
        body.accountName = accountName;
        body.providerName = providerName;
      }

      if (brokerUrl) {
        body.brokerUrl = brokerUrl;
        body.gatewayName = gatewayName;
        body.accountName = accountName;
        body.providerName = providerName;
      }

      if (brokerUrl) {
        body.brokerUrl = brokerUrl;
      }

      return body;
    };

    return {
      method,
      body: isSelectAll ? getParamsForAllOrders() : getParamsForSomeOrders(),
    };
  };

  const postOrders = async () => {
    setIsActionLoading(true);

    const isBrokerOrders =
      firstSelect === ActionNames.CancelViaBrokerProtocol ||
      (firstSelect === ActionNames.CancelViaJMX &&
        secondSelect === ActionNames.PlaceViaBrokerProtocol);

    if (isBrokerOrders) {
      try {
        const { actionId } = await orderManagementService.postBrokerOrders(
          preparePostData(),
        );
        redirectToReportInfoPage(actionId);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const { actionId } = await orderManagementService.postInternalOrders(
          preparePostData(),
        );
        redirectToReportInfoPage(actionId);
      } catch (error) {
        console.error(error);
      }
    }

    setIsActionLoading(false);
  };

  return { postOrders };
}
