import { ReactNode } from 'react';

import styles from './ApplicationWrapper.module.css';

interface IWrapperProps {
  children: ReactNode;
}

export const ApplicationWrapper = ({ children }: IWrapperProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};
