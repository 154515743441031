import { ReactComponent as NoDataIcon } from '../../assets/noData.svg';
import { useTranslator } from '../../contexts/TranslationContext';

import { TableNoDataStyled } from './Table.styled';

type NoDataProps = {
  noDataHeight?: string;
};

export const NoData = ({ noDataHeight }: NoDataProps) => {
  const { t } = useTranslator();

  return (
    <TableNoDataStyled style={{ height: noDataHeight || 'auto' }}>
      <NoDataIcon role="presentation" />
      <p>{t('ui__table__no_data')}</p>
    </TableNoDataStyled>
  );
};
