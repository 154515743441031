import {
  OrdersProps,
  OrdersParamsProps,
  PostOrdersParamsProps,
  FetchReportsListProps,
} from '~/pages/OrderManagement/types';
import { apiRequest } from '~/utils';

import { ORDER_MANAGEMENT_API } from './endpoints';

export class OrderManagementRepository {
  public static fetchOrderManagement(paramsData: OrdersParamsProps) {
    const {
      id,
      account,
      instrument,
      brokerUrl,
      module,
      limit,
      skip,
      brokerProviderName,
      brokerGatewayName,
      brokerAccountName,
    } = paramsData;

    // the line is split by comma and pipe
    const prepareIds = id?.split(/\||,\s+/).map((item) => item.trim());

    const params = {
      skip,
      limit,
      module,
      account,
      brokerUrl,
      instrument,
      id: prepareIds,
      brokerGatewayName,
      brokerAccountName,
      brokerProviderName,
    };

    return apiRequest<OrdersProps>({
      url: `${ORDER_MANAGEMENT_API}/aggregator/list`,
      params,
    });
  }

  public static modifyInternalOrders({ body, method }: PostOrdersParamsProps) {
    return apiRequest({
      method: 'POST',
      url: `${ORDER_MANAGEMENT_API}/internal/${method}`,
      data: body,
    });
  }

  public static modifyBrokerOrders({ body, method }: PostOrdersParamsProps) {
    return apiRequest({
      method: 'POST',
      url: `${ORDER_MANAGEMENT_API}/broker/${method}`,
      data: body,
    });
  }

  public static fetchModules() {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/aggregator/modules-metadata`,
    });
  }

  public static fetchOrderReportInfo(id: string) {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/report/${id}/info`,
    });
  }

  public static fetchOrderReportStepOneList(id: string) {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/report/${id}/action-list/failure/step-one`,
    });
  }

  public static fetchOrderReportStepTwoList(id: string) {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/report/${id}/action-list/failure/step-two`,
    });
  }

  public static fetchOrderReportSuccessList(id: string) {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/report/${id}/action-list/success`,
    });
  }

  public static fetchReportsList(paramsData: FetchReportsListProps) {
    const { limit, skip, username } = paramsData;

    const params = {
      skip,
      limit,
      username,
    };
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/report/list`,
      params,
    });
  }

  public static fetchBrokerUrls() {
    return apiRequest({
      url: `${ORDER_MANAGEMENT_API}/aggregator/broker-urls`,
    });
  }
}
