import {
  addMonths,
  format,
  getMonth,
  getYear,
  setYear,
  subMonths,
} from 'date-fns';
import { FC, useMemo } from 'react';

import { ArrowRightIcon, ArrowLeftIcon } from '../../../../../../Icons';
import { clampDateSafe, isWithinIntervalSafe } from '../helpers';

import {
  StyledCurrentMonth,
  StyledMonthSwitchButton,
} from './CalendarHeader.styled';
import { CalendarSelect } from './CalendarSelect';
import { generateMonths, generateYears } from './helpers';
import { ICalendarHeaderProps } from './types';

const YEAR_COUNT = 20;
const ARROW_ICON_SIZE = 26;

export const CalendarHeader: FC<ICalendarHeaderProps> = ({
  monthDate,
  changeMonth,
  changeYear,
  customHeaderCount,
  monthsShown = 1,
  increaseMonth,
  decreaseMonth,
  minDate,
  maxDate,
  locale,
}) => {
  const currentMonthName = useMemo(
    () => format(monthDate, 'LLLL', { locale }),
    [monthDate, locale],
  );

  const currentYear = getYear(monthDate);

  const months = useMemo(
    () => generateMonths(locale, monthDate, minDate, maxDate),
    [locale, monthDate, minDate, maxDate],
  );

  const years = useMemo(() => {
    let yearsCount = YEAR_COUNT;

    if (minDate && maxDate) {
      yearsCount = Math.floor(
        maxDate.getFullYear() - minDate.getFullYear() / 2,
      );
    }

    return generateYears(monthDate, yearsCount, minDate, maxDate);
  }, [monthDate, minDate, maxDate]);

  const isFirstMonth = customHeaderCount === 0;
  const isLastMonth = customHeaderCount === monthsShown - 1;

  const handleYearClick = (year: number) => {
    // get the date with year relative to the month header
    const dateFirst = setYear(monthDate, year);
    const dateFirstClamped = clampDateSafe(dateFirst, minDate, maxDate);

    changeYear(getYear(dateFirstClamped));
    changeMonth(getMonth(dateFirstClamped));
  };

  const isPrevInRange = isWithinIntervalSafe({
    date: subMonths(monthDate, 1),
    start: minDate,
    end: maxDate,
    ignoreDayAndTime: true,
  });

  const isNextInRange = isWithinIntervalSafe({
    date: addMonths(monthDate, 1),
    start: minDate,
    end: maxDate,
    ignoreDayAndTime: true,
  });

  return (
    <StyledCurrentMonth>
      {isFirstMonth ? (
        <StyledMonthSwitchButton
          onClick={decreaseMonth}
          disabled={!isPrevInRange}
        >
          <ArrowLeftIcon size={ARROW_ICON_SIZE} />
        </StyledMonthSwitchButton>
      ) : (
        <div />
      )}
      <div>
        <CalendarSelect
          title={currentMonthName}
          items={months}
          current={getMonth(monthDate)}
          onItemClick={changeMonth}
          disabled={!isFirstMonth}
        />{' '}
        <CalendarSelect
          title={currentYear}
          items={years}
          current={getYear(monthDate)}
          onItemClick={handleYearClick}
          disabled={!isFirstMonth}
        />
      </div>
      {isLastMonth ? (
        <StyledMonthSwitchButton
          onClick={increaseMonth}
          disabled={!isNextInRange}
        >
          <ArrowRightIcon size={ARROW_ICON_SIZE} />
        </StyledMonthSwitchButton>
      ) : (
        <div />
      )}
    </StyledCurrentMonth>
  );
};
