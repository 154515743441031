import { ThemeProvider } from '@mui/material/styles';

import { useTheme } from '../../theme';

import { StyledTab } from './styled';
import { IUITabProps } from './types';

export const Tab = <T,>({ children, label, ...rest }: IUITabProps & T) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledTab disableRipple label={label} {...rest} />
    </ThemeProvider>
  );
};
