import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Night = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M27 14V8M30 11h-6M21 3v4M23 5h-4M27.087 19.075A11.487 11.487 0 0112.925 4.913a11.5 11.5 0 1014.162 14.162v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 15V9M28 12h-6M19 4v4M21 6h-4M27.196 18.611A11.487 11.487 0 0112.466 5.04a11.5 11.5 0 1014.73 13.571v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M25 15V9M28 12h-6M19 4v4M21 6h-4M27.196 18.611A11.487 11.487 0 0112.466 5.04a11.5 11.5 0 1014.73 13.571v0z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M27 14V8M30 11h-6M21 3v4M23 5h-4M27.087 19.075A11.487 11.487 0 0112.925 4.913a11.5 11.5 0 1014.162 14.162v0z" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Night;
