import { styled } from '@mui/material/styles';

export const StyledTab = { paddingTop: '1px' };

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '36px 24px 0 24px',
  background: theme?.color?.bg?.primary,
}));

export const Head = styled('h1')(() => ({
  margin: '0 0 24px',
  font: '400 32px / 1 FoxSansPro, sans-serif',
}));

export const WrapperLoader = styled('div')(({ theme }) => ({
  height: '640px',
  background: theme?.color?.bg?.primary,
}));
