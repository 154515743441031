import { styled } from '@mui/material/styles';

import { blockNonNativeProps } from '../helpers';

import { IconProps } from './types';

export const StyledIconWrapper = styled('span', {
  shouldForwardProp: blockNonNativeProps(['color', 'size', 'autoSize']),
})<IconProps>(
  ({
    color: colorProp,
    size: sizeProp,
    autoSize,
    disabled,
    applyStates,
    theme,
  }) => {
    const defaultColor = applyStates
      ? theme?.color?.icon?.secondary
      : colorProp || 'currentColor';
    const hoverColor = applyStates
      ? theme?.color?.icon?.promo
      : colorProp || 'currentColor';
    const activeColor = applyStates
      ? theme?.color?.icon?.primary
      : colorProp || 'currentColor';
    const opacity = applyStates && disabled ? 0.4 : 1;
    const size = sizeProp ? `${sizeProp}px` : '16px';

    return {
      width: autoSize ? '1em' : size,
      height: autoSize ? '1em' : size,
      display: 'inline-flex',
      fontSize: 'inherit',
      color: defaultColor,
      opacity,
      '&:hover:not([disabled])': {
        color: hoverColor,
      },
      '&:active:not([disabled])': {
        color: activeColor,
      },
    };
  },
);
