import { createTheme, ThemeProvider } from '@mui/material/styles';
import { merge } from 'lodash';
import { FC, useMemo } from 'react';
import { ThemeProvider as ReactUiKitThemeProvider } from 'react-ui-kit-exante';

import { theme } from './index';

export const ComposedThemeProvider: FC = ({ children }) => {
  const brandingTheme = window.ORDER_MANAGEMENT_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return (
    <ThemeProvider theme={themeValue}>
      <ReactUiKitThemeProvider theme={themeValue}>
        {children}
      </ReactUiKitThemeProvider>
    </ThemeProvider>
  );
};
