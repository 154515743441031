import { ThemeProvider } from '@mui/material/styles';
import { FC } from 'react';

import { useTheme } from '../../theme/useTheme';

import { TabPanelProps } from './types';

export const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <div hidden={value !== index} {...rest}>
        {value === index ? children : null}
      </div>
    </ThemeProvider>
  );
};
