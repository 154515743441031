import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const HTTP = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.8 6H5.2A1.2 1.2 0 004 7.2V24a1.2 1.2 0 001.2 1.2h21.6A1.2 1.2 0 0028 24V7.2A1.2 1.2 0 0026.8 6zM4 10.8h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.095 20.21v-4.736c0-.277-.25-.474-.543-.474-.293 0-.543.197-.543.474v1.894H8.086v-1.894c0-.277-.25-.474-.543-.474-.292 0-.543.197-.543.474v4.736c0 .277.25.474.543.474.293 0 .543-.197.543-.474v-1.894h1.923v1.895c0 .276.25.473.543.473.293 0 .543-.197.543-.474zM15.4 15.079h-3.175a.49.49 0 00-.502.474c0 .244.209.473.502.473h1.044v4.184c0 .277.251.474.543.474.293 0 .544-.197.544-.474v-4.184H15.4a.489.489 0 00.502-.473.49.49 0 00-.502-.474zM19.906 15.079H16.73a.49.49 0 00-.502.474c0 .244.21.473.502.473h1.044v4.184c0 .277.251.474.544.474.292 0 .543-.197.543-.474v-4.184h1.045a.489.489 0 00.501-.473.49.49 0 00-.501-.474zM22.91 17.763h-.793v-1.737h.794c.635 0 1.002.356 1.002.869s-.367.868-1.002.868zm-.793.947h.794c1.245 0 2.089-.781 2.089-1.815 0-1.034-.844-1.816-2.09-1.816h-1.337c-.292 0-.543.197-.543.474v4.657c0 .277.25.474.543.474.293 0 .544-.197.544-.474v-1.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.8 6H5.2A1.2 1.2 0 004 7.2V24a1.2 1.2 0 001.2 1.2h21.6A1.2 1.2 0 0028 24V7.2A1.2 1.2 0 0026.8 6zM4 9.8h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.095 19.21v-4.736c0-.277-.25-.474-.543-.474-.293 0-.543.197-.543.474v1.894H8.086v-1.894c0-.277-.25-.474-.543-.474-.292 0-.543.197-.543.474v4.736c0 .277.25.474.543.474.293 0 .543-.197.543-.474v-1.894h1.923v1.895c0 .276.25.473.543.473.293 0 .543-.197.543-.474zM15.4 14.079h-3.175a.49.49 0 00-.502.474c0 .244.209.473.502.473h1.044v4.184c0 .277.251.474.543.474.293 0 .544-.197.544-.474v-4.184H15.4a.489.489 0 00.502-.473.49.49 0 00-.502-.474zM19.906 14.079H16.73a.49.49 0 00-.502.474c0 .244.21.473.502.473h1.044v4.184c0 .277.251.474.544.474.292 0 .543-.197.543-.474v-4.184h1.045a.489.489 0 00.501-.473.49.49 0 00-.501-.474zM22.91 16.763h-.793v-1.737h.794c.635 0 1.002.356 1.002.869s-.367.868-1.002.868zm-.793.947h.794c1.245 0 2.089-.781 2.089-1.815 0-1.034-.844-1.816-2.09-1.816h-1.337c-.292 0-.543.197-.543.474v4.657c0 .277.25.474.543.474.293 0 .544-.197.544-.474v-1.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M26.8 6H5.2A1.2 1.2 0 004 7.2V24a1.2 1.2 0 001.2 1.2h21.6A1.2 1.2 0 0028 24V7.2A1.2 1.2 0 0026.8 6zM4 9.8h24"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.095 19.21v-4.736c0-.277-.25-.474-.543-.474-.293 0-.543.197-.543.474v1.894H8.086v-1.894c0-.277-.25-.474-.543-.474-.292 0-.543.197-.543.474v4.736c0 .277.25.474.543.474.293 0 .543-.197.543-.474v-1.894h1.923v1.895c0 .276.25.473.543.473.293 0 .543-.197.543-.474zM15.4 14.079h-3.175a.49.49 0 00-.502.474c0 .244.209.473.502.473h1.044v4.184c0 .277.251.474.543.474.293 0 .544-.197.544-.474v-4.184H15.4a.489.489 0 00.502-.473.49.49 0 00-.502-.474zM19.906 14.079H16.73a.49.49 0 00-.502.474c0 .244.21.473.502.473h1.044v4.184c0 .277.251.474.544.474.292 0 .543-.197.543-.474v-4.184h1.045a.489.489 0 00.501-.473.49.49 0 00-.501-.474zM22.91 16.763h-.793v-1.737h.794c.635 0 1.002.356 1.002.869s-.367.868-1.002.868zm-.793.947h.794c1.245 0 2.089-.781 2.089-1.815 0-1.034-.844-1.816-2.09-1.816h-1.337c-.292 0-.543.197-.543.474v4.657c0 .277.25.474.543.474.293 0 .544-.197.544-.474v-1.5z"
            fill="currentColor"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g opacity={0.4}>
            <path
              d="M26.8 6H5.2A1.2 1.2 0 004 7.2V24a1.2 1.2 0 001.2 1.2h21.6A1.2 1.2 0 0028 24V7.2A1.2 1.2 0 0026.8 6zM4 10.8h24"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.095 20.21v-4.736c0-.277-.25-.474-.543-.474-.293 0-.543.197-.543.474v1.894H8.086v-1.894c0-.277-.25-.474-.543-.474-.292 0-.543.197-.543.474v4.736c0 .277.25.474.543.474.293 0 .543-.197.543-.474v-1.894h1.923v1.895c0 .276.25.473.543.473.293 0 .543-.197.543-.474zM15.4 15.079h-3.175a.49.49 0 00-.502.474c0 .244.209.473.502.473h1.044v4.184c0 .277.251.474.543.474.293 0 .544-.197.544-.474v-4.184H15.4a.489.489 0 00.502-.473.49.49 0 00-.502-.474zM19.906 15.079H16.73a.49.49 0 00-.502.474c0 .244.21.473.502.473h1.044v4.184c0 .277.251.474.544.474.292 0 .543-.197.543-.474v-4.184h1.045a.489.489 0 00.501-.473.49.49 0 00-.501-.474zM22.91 17.763h-.793v-1.737h.794c.635 0 1.002.356 1.002.869s-.367.868-1.002.868zm-.793.947h.794c1.245 0 2.089-.781 2.089-1.815 0-1.034-.844-1.816-2.09-1.816h-1.337c-.292 0-.543.197-.543.474v4.657c0 .277.25.474.543.474.293 0 .544-.197.544-.474v-1.5z"
              fill="currentColor"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default HTTP;
