import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Delete = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 8h24M10.667 8V5.333a2.667 2.667 0 012.666-2.666h5.334a2.667 2.667 0 012.666 2.666V8m4 0v18.667a2.666 2.666 0 01-2.666 2.666H9.333a2.667 2.667 0 01-2.666-2.666V8h18.666zM13.333 14.667v8M18.667 14.667v8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 8h24M10.667 8V5.333a2.667 2.667 0 012.666-2.666h5.334a2.667 2.667 0 012.666 2.666V8m4 0v18.667a2.666 2.666 0 01-2.666 2.666H9.333a2.667 2.667 0 01-2.666-2.666V8h18.666zM20 14.047L12.047 22M11.838 14.047L19.791 22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4 8h24M10.667 8V5.333a2.667 2.667 0 012.666-2.666h5.334a2.667 2.667 0 012.666 2.666V8m4 0v18.667a2.666 2.666 0 01-2.666 2.666H9.333a2.667 2.667 0 01-2.666-2.666V8h18.666zM20 14.047L12.047 22M11.838 14.047L19.791 22"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4 8h24M10.667 8V5.333a2.667 2.667 0 012.666-2.666h5.334a2.667 2.667 0 012.666 2.666V8m4 0v18.667a2.667 2.667 0 01-2.666 2.666H9.333a2.667 2.667 0 01-2.666-2.666V8h18.666zM13.333 14.667v8M18.667 14.667v8" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Delete;
