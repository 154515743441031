import * as React from 'react';
import { useEffect, useState } from 'react';

import { StyledIconWrapper } from './IconWrapper.styled';
import { DEFAULT_APPLY_STATES } from './constants';
import { IconProps } from './types';

export const IconWrapper: React.FC<
  {
    iconDefault: React.ReactNode;
    iconHover?: React.ReactNode;
    iconActive?: React.ReactNode;
    iconDisabled?: React.ReactNode;
  } & IconProps
> = ({
  applyStates = DEFAULT_APPLY_STATES,
  dataTestId = '',
  disabled,
  iconActive,
  iconDefault,
  iconDisabled,
  iconHover,
  isActive,
  isHover,
  ...restProps
}) => {
  const withHover = !disabled && iconHover && applyStates;
  const withActive = !disabled && iconActive && applyStates;
  const withDisabled = iconDisabled && applyStates;

  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);

  let icon = iconDefault;
  if (isHover || (hover && withHover)) {
    icon = iconHover;
  }
  if (isActive || (active && withActive)) {
    icon = iconActive;
  }
  if (disabled && withDisabled) {
    icon = iconDisabled;
  }

  useEffect(() => {
    setHover(isHover);
  }, [isHover]);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <StyledIconWrapper
      applyStates={applyStates}
      disabled={disabled}
      data-test-id={dataTestId}
      {...restProps}
      {...(withHover && { onMouseOver: () => setHover(true) })}
      {...(withHover && { onMouseOut: () => setHover(false) })}
      {...(withActive && { onMouseDown: () => setActive(true) })}
      {...(withActive && { onMouseUp: () => setActive(false) })}
    >
      {icon}
    </StyledIconWrapper>
  );
};
