import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Help = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.667 8.636 2.667 16S8.637 29.333 16 29.333z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 21.333a5.333 5.333 0 100-10.666 5.333 5.333 0 000 10.666zM6.573 6.574l5.653 5.653M19.773 19.773l5.654 5.654M19.773 12.227l5.654-5.653M19.773 12.227L24.48 7.52M6.573 25.427l5.653-5.654"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M2.667 16c0 7.364 5.97 13.333 13.333 13.333 7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.667 8.636 2.667 16z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.667 16a5.333 5.333 0 1010.666 0 5.333 5.333 0 00-10.666 0zM25.426 6.573l-5.653 5.653M12.227 19.773l-5.654 5.654M19.773 19.773l5.653 5.654M19.773 19.773l4.707 4.707M6.573 6.573l5.654 5.653"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M2.667 16c0 7.364 5.97 13.333 13.333 13.333 7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.667 8.636 2.667 16z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.667 16a5.333 5.333 0 1010.666 0 5.333 5.333 0 00-10.666 0zM25.427 6.573l-5.654 5.653M12.227 19.773l-5.654 5.654M19.773 19.773l5.654 5.654M19.773 19.773l4.707 4.707M6.573 6.573l5.654 5.653"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.667 8.636 2.667 16S8.637 29.333 16 29.333z" />
            <path d="M16 21.333a5.333 5.333 0 100-10.667 5.333 5.333 0 000 10.667zM6.573 6.574l5.653 5.653M19.773 19.773l5.654 5.654M19.773 12.227l5.654-5.653M19.773 12.227L24.48 7.52M6.573 25.427l5.653-5.654" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Help;
